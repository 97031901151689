import { ModalMessage } from ".";

const Messages: Record<string, ModalMessage> = {
  RemoveAccount: {
    identifier: "RemoveAccount",
    header: "Delete Profile",
    text: "Are you sure you want to delete your profile?"
  },
  AccountDeleted: {
    identifier: "AccountDeleted",
    header: "Account Deleted",
    text: "Your account has successfully been deleted."
  },
  LeaveTeam: {
    identifier: "LeaveTeam",
    header: "Leave team",
    text: "Are you sure you want to leave this team?"
  },
  Score: {
    identifier: "Score",
    header: "Congratulations"
  },
  Error: {
    identifier: "Error",
    header: "Error"
  }
};
export default Messages;
