import logger from "@/utilities/logger";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/compat/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/compat/analytics";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

import { MIN_SAFE_DAYJS_DATE } from "./constants";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: "knattra-code-competition.firebaseapp.com",
  databaseURL: "https://knattra-code-competition.firebaseio.com",
  projectId: "knattra-code-competition",
  storageBucket: "knattra-code-competition.appspot.com",
  messagingSenderId: "1070416305619",
  appId: "1:1070416305619:web:20d67ce27798635e14a326",
  measurementId: "G-BHDR0R3FQW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//// Exports

// Firebase services
export default firebase;

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.app().functions("europe-west1");

// Firebase error codes
export const popUpClosedError = "auth/popup-closed-by-user";

export const isCompetitionOpen = async () => {
  const settingsRef = () => db.collection("settings");

  const dev =
    process.env.VUE_APP_SHOW_PROBLEMS === "true" ||
    window.location.hash.includes("?dev=");

  const competitionOpen = await settingsRef()
    .doc("competitionOpen")
    .get();
  const start =
    dayjs(competitionOpen.data()?.start.toDate()) ?? MIN_SAFE_DAYJS_DATE;
  const end =
    dayjs(competitionOpen?.data()?.end.toDate()) ?? MIN_SAFE_DAYJS_DATE;
  const now = dayjs();
  const date = now.isBetween(start, end);

  return dev || date;
};

const useAuthEmulator = process.env.VUE_APP_AUTH_EMULATOR == "true";
const useFunctionsEmulator = process.env.VUE_APP_FUNCTIONS_EMULATOR == "true";
const useFirestoreEmulator = process.env.VUE_APP_FIRESTORE_EMULATOR == "true";

if (useAuthEmulator) {
  logger.debug("Frontend will call Auth emulator");
  auth.useEmulator("http://localhost:9099/");
}
if (useFunctionsEmulator) {
  logger.debug("Frontend will call Functions emulator");
  functions.useEmulator("localhost", 5001);
}
if (useFirestoreEmulator) {
  logger.debug("Frontend will call Firestore emulator");
  db.settings({ host: "localhost:5002", ssl: false, merge: true });
}
