




































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState, mapMutations, mapActions } from "vuex";
import { OpenModal } from "../store";
import { auth, functions, popUpClosedError } from "../firebase";
import AppButton from "@/components/AppButton.vue";
import Title from "@/components/Title.vue";
import MyAccountRow from "@/components/Text/MyAccountRow.vue";

@Component({
  components: {
    AppButton,
    Title,
    MyAccountRow
  },
  computed: {
    ...mapState(["isAuth", "user", "team", "teammates"])
  },
  methods: {
    ...mapActions(["signIn"]),
    ...mapMutations(["setLoading", "setTeam", "openModal", "cancelModal"])
  }
})
export default class MyAccount extends Vue {
  private isAuth!: boolean;
  private signIn!: () => Promise<any>;
  private openModal!: (modal: OpenModal) => void;
  private cancelModal!: () => void;
  private setLoading!: (loading: boolean) => Promise<any>;
  private setTeam!: (team: string | null) => void;
  private loading = false;
  private teamName: string | null = null;
  private teamCode: string | null = null;

  private async createPage(): Promise<void> {
    if (this.isAuth) {
      this.$router.push({
        name: "My Account",
        params: { site: "create-team" }
      });
    }
  }

  private async joinPage(): Promise<void> {
    if (this.isAuth) {
      this.$router.push({
        name: "My Account",
        params: { site: "join-team" }
      });
    }
  }

  private async createTeam() {
    const teamCreate = functions.httpsCallable("team-create");
    this.loading = true;
    this.setLoading(true);
    try {
      const res = await teamCreate({ name: this.teamName });
      this.setTeam(res.data.team);
      this.teamName = null;
      this.$router.push({ name: "My Account" });
    } catch (error) {
      this.openModal({ type: "Error", customText: error.message });
    }
    this.loading = false;
    this.setLoading(false);
  }

  private async joinTeam() {
    const teamJoin = functions.httpsCallable("team-join");
    this.loading = true;
    this.setLoading(true);
    try {
      const res = await teamJoin({ key: this.teamCode });
      this.setTeam(res.data.team);
      this.teamCode = null;
      this.$router.push({ name: "My Account" });
    } catch (error) {
      this.openModal({ type: "Error", customText: error.message });
    }
    this.loading = false;
    this.setLoading(false);
  }

  async leaveTeam() {
    this.setLoading(true);
    const teamLeave = functions.httpsCallable("team-leave");
    try {
      await teamLeave();
      this.setTeam(null);
      this.cancelModal();
    } catch (error) {
      this.openModal({ type: "Error", customText: error.message });
    }
    this.setLoading(false);
  }

  async deleteProfile() {
    this.setLoading(true);
    try {
      await this.signIn();
      await auth.currentUser?.delete();
      this.$router.push({ name: "Home" });
      this.openModal({ type: "AccountDeleted" });
    } catch (error) {
      if (error.code !== popUpClosedError) {
        this.openModal({ type: "Error", customText: error.message });
      }
    }
    this.setLoading(false);
  }

  private async onCancel() {
    this.$router.push({ name: "My Account" });
  }
}
