



















import AppButton from "../AppButton.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: { AppButton } })
export default class MyAccountRow extends Vue {
  @Prop() private title!: string;
  @Prop() private buttonTitle!: string;
  @Prop() private buttonOnClick!: () => void;
  @Prop({ default: false }) private buttonHidden?: boolean;
}
