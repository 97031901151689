

























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import LinkButton from "@/components/LinkButton.vue";
import Title from "@/components/Title.vue";

@Component({
  components: {
    LinkButton,
    Title
  }
})
export default class About extends Vue {
  sendEmail() {
    window.location.href = "mailto:knattra@jayway.com";
  }
}
