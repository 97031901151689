import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { auth, db } from "./firebase";
import logger from "@/utilities/logger";
import { Team, Solution } from "@/models/db/team";
import { Problem } from "@/models/db/problem";
import { User } from "@/models/db/user";
import { Settings } from "@/models/db/settings";

Vue.config.productionTip = false;

let unsubscribeUser: Function | null;
let unsubscribeTeam: Function | null;
let unsubscribeTeamUsers: Function | null;
type GoogleUser = Record<any, any> | null;

const syncTeam = async (user: User | null) => {
  logger.debug("Running syncTeam");
  unsubscribeTeam && unsubscribeTeam();
  unsubscribeTeamUsers && unsubscribeTeamUsers();
  if (user && user.teamId) {
    unsubscribeTeam = db
      .collection("teams")
      .doc(user.teamId)
      .onSnapshot(doc => {
        store.commit("setTeam", (doc.data() as Team) || null);

        unsubscribeTeamUsers && unsubscribeTeamUsers();
        unsubscribeTeamUsers = db
          .collection("users")
          .where("teamId", "==", user.teamId)
          .onSnapshot(users => {
            store.commit(
              "setTeammates",
              users.docs.map<string>(doc => doc.data().email)
            );
          });
      });
  } else {
    store.commit("setTeam", null);
  }
  logger.debug("End syncTeam");
};

const syncUser = async (user: GoogleUser) => {
  logger.debug("Running syncUser");
  unsubscribeUser && unsubscribeUser();
  if (user) {
    unsubscribeUser = db
      .collection("users")
      .doc(user.uid)
      .onSnapshot(doc => {
        const userData = (doc.data() as User) || null;
        store.commit("setUser", userData);

        syncTeam(userData);
      });
  } else {
    store.commit("setUser", null);
    syncTeam(null);
  }
  logger.debug("End syncUser");
};

const syncSolutions = async () => {
  logger.debug("Running syncSolutions");
  db.collectionGroup("solutions")
    .orderBy("date")
    .onSnapshot(solutionsDb => {
      const solutionsData = solutionsDb.docs.map(i => {
        const solutionData = i.data() as Solution;
        solutionData.id = i.id;
        return solutionData;
      });
      store.commit("setSolutions", solutionsData);
    });
  logger.debug("End syncSolutions");
};

const syncTeams = async () => {
  logger.debug("Running syncTeams");
  db.collection("teams")
    .orderBy("name")
    .onSnapshot(teamsDb => {
      const teamsData = teamsDb.docs
        .map(i => {
          const team = i.data() as Team;
          team.id = i.id;
          return team;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      store.commit("setTeams", teamsData);
    });
  logger.debug("End syncTeams");
};

const syncProblems = async () => {
  logger.debug("Running syncProblems");
  db.collection("problems")
    .where("active", "==", true)
    .orderBy("order")
    .onSnapshot(problemsDb => {
      let problemsData = problemsDb.docs.map(i => {
        const problem = i.data() as Problem;
        problem.id = i.id;
        return problem;
      });
      problemsData = problemsData.filter(
        problem => problem.id !== process.env.VUE_APP_EXAMPLE_PROBLEM_ID
      );
      store.commit("setProblems", problemsData);
    });
  logger.debug("End syncProblems");
};

const syncSettings = async () => {
  logger.debug("Running syncSettings");
  db.collection("settings").onSnapshot(settingsDb => {
    const settingsDataObj: any = {};
    settingsDb.docs.forEach(i => {
      settingsDataObj[i.id] = i.data();
    });
    const settingsData = settingsDataObj as Settings;
    store.commit("setSettings", settingsData);
  });
  logger.debug("End syncSettings");
};

const sync = async () => {
  logger.debug("Running sync");

  const solutions = syncSolutions();
  const teams = syncTeams();
  const problems = syncProblems();
  const settings = syncSettings();

  await Promise.all([solutions, teams, problems, settings]);

  logger.debug("End sync");
};

sync();

auth.onAuthStateChanged(user => {
  syncUser(user);
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
