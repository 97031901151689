import { Team, Solution } from "@/models/db/team";
import { ScoreTeam, ScoreTeamsSolutions } from "@/models/score/scoreteam";
import { Problem } from "@/models/db/problem";
import { User } from "@/models/db/user";
import Vue from "vue";
import Vuex from "vuex";
import firebase, { auth } from "@/firebase";
import MESSAGES from "./messages";
import { Settings } from "@/models/db/settings";
import { MIN_SAFE_DAYJS_DATE } from "@/constants";
import getPriority from "@/store/priority";

import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

export type OpenModal = {
  type: string;
  customText?: string;
  confirmAction?: () => void;
};

export type ModalMessage = {
  identifier: string;
  header: string;
  text?: string;
  confirmAction?: () => void;
};

Vue.use(Vuex);

export type State = {
  loading: boolean;
  isAuth: boolean;
  isSmallDevice: boolean;
  user: User | null;
  team: Team | null;
  problems: Problem[];
  solutions: Solution[];
  teams: Team[];
  settings: Settings | null;
  modal: ModalMessage | null;
  teammates: string[];
};

const initialState: State = {
  loading: false,
  isAuth: false,
  isSmallDevice: false,
  user: null,
  team: null,
  problems: [],
  solutions: [],
  teams: [],
  settings: null,
  modal: null,
  teammates: []
};
export default new Vuex.Store({
  state: initialState,
  mutations: {
    setUser(state, user: User) {
      state.user = user;
      state.isAuth = !!user;
    },
    setWindowWidth(state, width) {
      state.isSmallDevice = width < 900;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setTeam(state, team: Team | null) {
      state.team = team;
    },
    setTeams(state, teams: Team[]) {
      state.teams = teams;
    },
    setProblems(state, problems: Problem[]) {
      state.problems = problems;
    },
    setSolutions(state, solutions: Solution[]) {
      state.solutions = solutions;
    },
    cleanState(state) {
      Object.assign(state, {
        ...state,
        ...initialState
      });
    },
    openModal(state, { type, customText, confirmAction }: OpenModal) {
      state.modal = MESSAGES[type];
      if (customText) {
        state.modal.text = customText;
      }
      if (confirmAction) {
        state.modal.confirmAction = confirmAction;
      }
    },
    cancelModal(state) {
      state.modal = null;
    },
    setTeammates(state, teammates: string[]) {
      state.teammates = teammates;
    },
    setSettings(state, settings: Settings) {
      state.settings = settings;
    }
  },
  actions: {
    async signIn() {
      return await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    },
    async signOut(context) {
      context.commit("cleanState");
      return await auth.signOut();
    }
  },
  getters: {
    teams({ teams }) {
      return teams.map((sb: Team) => sb.name);
    },
    scoreboard({ problems, solutions, teams }) {
      // hs = high score
      const hs: any = {};

      const teamsSolutions: ScoreTeamsSolutions = solutions.reduce(
        (res: any, solution: Solution) => {
          if (!solution) {
            return res;
          }
          const { id, teamId } = solution;
          const teamSolutions = res[teamId] || {};
          teamSolutions[id] = solution;
          res[teamId] = teamSolutions;
          return res;
        },
        {}
      );

      const teamsBestSolutions = solutions.reduce(
        (res: any, solution: Solution) => {
          if (!solution) {
            return res;
          }
          const { teamId, problemId } = solution;
          const teamBestSolutions = res[teamId] || {};
          const currentSolution = teamBestSolutions[problemId];
          if (!currentSolution || solution.score > currentSolution.score) {
            teamBestSolutions[problemId] = solution;
          }
          if (!hs[problemId] || solution.score > hs[problemId].score) {
            hs[problemId] = solution;
          }
          res[teamId] = teamBestSolutions;
          return res;
        },
        {}
      );

      return teams
        .map(team => team as ScoreTeam)
        .map(team => {
          const teamProblems = problems.map(problem => {
            if (
              !teamsBestSolutions[team.id] ||
              !teamsBestSolutions[team.id][problem.id]
            ) {
              return {};
            }

            const bestSolution = teamsBestSolutions[team.id][problem.id];
            const highscore = hs[problem.id].score;

            // Not sure if this if is needed, looks like it'll always be true?
            // Investigate in the future
            if (team.id === bestSolution.teamId) {
              return {
                id: problem.id,
                ...bestSolution,
                highscore
              };
            }
          });

          const zTotalScore = teamProblems.reduce((a: number, b: any) => {
            return a + (b.score / b.highscore || 0);
          }, 0);
          const totalScore = Math.round(zTotalScore * 1000);

          const earliestBestSolution = MIN_SAFE_DAYJS_DATE;

          return {
            ...team,
            problems: teamProblems,
            totalScore,
            earliestBestSolution
          };
        })
        .sort((a, b) => {
          // Sort by score, then by earliest best solution
          const scoreDifference = b.totalScore - a.totalScore;
          if (scoreDifference != 0) {
            return scoreDifference;
          }
          const aTeamsSolutions = teamsSolutions[a.id];
          const bTeamsSolutions = teamsSolutions[b.id];
          if (!aTeamsSolutions) {
            return 1;
          } else if (!bTeamsSolutions) {
            return -1;
          }
          const { res, aRes, bRes } = getPriority(
            aTeamsSolutions,
            bTeamsSolutions,
            solutions
          );
          a.earliestBestSolution = aRes;
          b.earliestBestSolution = bRes;
          return res;
        })
        .reduce((acc, val, idx) => {
          if (idx === 0) {
            return acc.concat([{ ...val, rank: 1 }]);
          }

          const previous = acc[idx - 1];
          const isScoreSame = val.totalScore === previous.totalScore;
          const isEarliestBestSolutionSame = val.earliestBestSolution.isSame(
            previous.earliestBestSolution
          );
          const rank =
            isScoreSame && isEarliestBestSolutionSame ? previous.rank : idx + 1;

          return acc.concat([{ ...val, rank }]);
        }, [] as ScoreTeam[])
        .filter(team => team.problems.some(problem => problem.score > 0));
    }
  },
  modules: {}
});
