


















import { Component, Vue, Prop } from "vue-property-decorator";
import { functions } from "../firebase";
import AppButton from "@/components/AppButton.vue";
import { mapMutations } from "vuex";
import { OpenModal } from "../store";

@Component({
  components: { AppButton },
  methods: { ...mapMutations(["setLoading", "openModal"]) }
})
export default class Problem extends Vue {
  @Prop() private index!: number;
  @Prop() private problem!: any;
  private description = "";
  private loading = false;
  private data = "";
  private setLoading!: (loading: boolean) => Promise<any>;
  private openModal!: (modal: OpenModal) => void;

  created() {
    this.getDescription().then(data => (this.description = data));
  }

  async getDescription() {
    const normalizedName = this.problem.name.replace(/\W/g, "");

    const html = await import(`@/descriptions/${normalizedName}.html`);
    return html.default;
  }

  async postAnswer(input: string) {
    const validateSolution = functions.httpsCallable("solution-validate");
    this.loading = true;
    const timeout = setTimeout(() => this.setLoading(true), 2000);
    try {
      const res = await validateSolution({ problemId: this.problem.id, input });
      this.openModal({ type: "Score", customText: res.data.message });
    } catch (error) {
      this.openModal({ type: "Error", customText: error.message });
    }
    this.loading = false;
    clearTimeout(timeout);
    this.setLoading(false);
  }

  async getScore() {
    await this.postAnswer(this.data);
    this.data = "";
  }
}
