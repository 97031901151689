









import { Component, Vue } from "vue-property-decorator";
import StartInformation from "@/components/Text/StartInformation.vue";
import Title from "@/components/Title.vue";

@Component({
  components: { Title, StartInformation }
})
export default class CompetitionInformation extends Vue {}
