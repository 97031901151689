import { Solution } from "@/models/db/team";
import { ScoreTeamSolutions } from "@/models/score/scoreteam";
import dayjs from "dayjs";
import firebase from "@/firebase";
import { MIN_SAFE_DAYJS_DATE } from "@/constants";

import Timestamp = firebase.firestore.Timestamp;

const isBestSolutionOverall = (
  solution: Solution,
  otherTeamsBest: Solution | undefined
): boolean => {
  if (!otherTeamsBest || solution.score > otherTeamsBest.score) {
    return true;
  }
  return false;
};

const getFirstBestSolution = (
  thisTeams: Solution[],
  otherTeamsBest: Solution | undefined
): Solution => {
  const otherBestSolutionScore = otherTeamsBest?.score ?? 0;
  for (const solution of thisTeams) {
    if (solution.score > otherBestSolutionScore) {
      return solution;
    }
  }
  return thisTeams[0];
};

// Find the "earliest best solution" for tie-breaking for teams:
//  * If the team is leading the scoring for a problem, the solution that made them end up on top (the submission
//      which resulted in them being "bumped up" on the scoreboard for that problem) will be used
//  * If the team isn't leading the scoring for a problem, the earliest solution with highest score will be used
//
// Extra care had to be taken due to the fact that if your team is leading the scoring on a problem, and submit
// a new solution which has higher score than your previous leading score, you can't outright pick the date from
// the new solution as this would otherwise bump them downwards in priority among the teams which has the same score
const getEarliestBestSolution = (
  thisTeams: Solution[],
  allSolutions: Solution[]
): dayjs.Dayjs => {
  // Map up all the best solutions for this team
  const bestSolutions: Map<string, Solution> = new Map();
  for (const solution of thisTeams) {
    if (!bestSolutions.has(solution.problemId)) {
      bestSolutions.set(solution.problemId, solution);
    } else {
      const currentBest = bestSolutions.get(solution.problemId);
      if (!currentBest || solution.score > currentBest?.score) {
        bestSolutions.set(solution.problemId, solution);
      }
    }
  }

  const earliestBestSolutions: Solution[] = [];
  bestSolutions.forEach(s => {
    // Extract the other teams' solutions for this problem
    const otherTeamsSolutions = allSolutions
      .filter(s1 => s1.teamId !== s.teamId)
      .filter(s1 => s1.problemId === s.problemId);
    // Sort other teams solutions by score descending
    otherTeamsSolutions.sort((a, b) => (a.score - b.score) * -1);
    const otherTeamsBestSolution = otherTeamsSolutions[0];
    const isBest = isBestSolutionOverall(s, otherTeamsBestSolution);
    if (isBest) {
      const solutionsForProblem = thisTeams.filter(
        s1 => s1.problemId === s.problemId
      );
      earliestBestSolutions.push(
        getFirstBestSolution(solutionsForProblem, otherTeamsBestSolution)
      );
    } else {
      const temp = bestSolutions.get(s.problemId);
      if (temp) {
        earliestBestSolutions.push(temp);
      }
    }
  });

  // Now that we have all the earliest best solution dates for this team, we sort the dates descending and
  // take the first in the array (= the most recent)
  const earliestBestSolutionsDates = earliestBestSolutions
    .map(s => {
      const { date } = s;
      return dayjs(new Timestamp(date.seconds, date.nanoseconds).toDate());
    })
    .sort((a, b) => {
      return a.isAfter(b) ? -1 : 1;
    });
  return earliestBestSolutionsDates[0];
};

const getPriority = (
  a: ScoreTeamSolutions,
  b: ScoreTeamSolutions,
  allSolutions: Solution[]
): { res: number; aRes: dayjs.Dayjs; bRes: dayjs.Dayjs } => {
  if (typeof a === undefined && typeof b === undefined) {
    return { res: 0, aRes: MIN_SAFE_DAYJS_DATE, bRes: MIN_SAFE_DAYJS_DATE };
  } else if (typeof a === undefined) {
    return { res: 1, aRes: MIN_SAFE_DAYJS_DATE, bRes: MIN_SAFE_DAYJS_DATE };
  } else if (typeof b === undefined) {
    return { res: -1, aRes: MIN_SAFE_DAYJS_DATE, bRes: MIN_SAFE_DAYJS_DATE };
  }

  const aSolutionIds = Object.keys(a);
  const bSolutionIds = Object.keys(b);

  const aSolutions: Solution[] = aSolutionIds.map(id => a[id]);
  const aRes = getEarliestBestSolution(aSolutions, allSolutions);

  const bSolutions: Solution[] = bSolutionIds.map(id => b[id]);
  const bRes = getEarliestBestSolution(bSolutions, allSolutions);

  const res = aRes.isAfter(bRes) ? 1 : -1;
  return { res: res, aRes: aRes, bRes: bRes };
};

export default getPriority;
