




































































import { Component, Vue } from "vue-property-decorator";
import { mapState, mapMutations, mapActions } from "vuex";
import { OpenModal } from "./store";
import LinkButton from "@/components/LinkButton.vue";
import AppButton from "@/components/AppButton.vue";
import Hamburger from "@/assets/Hamburger.vue";
import Profile from "@/assets/Profile.vue";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/Modal.vue";
import Loading from "@/components/Loading.vue";
import { popUpClosedError } from "./firebase";

@Component({
  computed: {
    ...mapState(["isAuth", "isSmallDevice", "modal"])
  },
  methods: {
    ...mapActions(["signIn", "signOut"]),
    ...mapMutations([
      "setWindowWidth",
      "openModal",
      "cancelModal",
      "setLoading"
    ])
  },
  components: {
    LinkButton,
    Footer,
    Hamburger,
    Loading,
    Profile,
    AppButton,
    Modal
  }
})
export default class App extends Vue {
  private isAuth!: boolean;
  private setWindowWidth!: (width: number) => void;
  private signIn!: () => Promise<void>;
  private signOut!: () => Promise<void>;
  private setLoading!: (loading: boolean) => Promise<any>;
  private openModal!: (modal: OpenModal) => void;
  private navBarOpen = false;
  private isResizeActive = false;

  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  destroyed() {
    window.removeEventListener("resize", this.onResize);
  }

  get authName() {
    return this.isAuth ? "sign out." : "sign in.";
  }

  onResize() {
    if (this.isResizeActive) {
      return;
    }
    this.isResizeActive = true;
    this.setWindowWidth(window.innerWidth);
    setTimeout(() => {
      this.isResizeActive = false;
    }, 100);
  }

  toggleNavBar() {
    this.navBarOpen = !this.navBarOpen;
  }

  async onSignInButton() {
    this.setLoading(true);
    try {
      if (this.isAuth) {
        await this.signOut();
      } else {
        await this.signIn();
      }
    } catch (error) {
      if (error.code !== popUpClosedError) {
        this.openModal({ type: "Error", customText: error.message });
      }
    }
    this.setLoading(false);
  }
}
