




















import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: mapState(["loading"])
})
export default class Loading extends Vue {}
