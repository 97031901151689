import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import MyAccount from "../views/MyAccount.vue";
import About from "../views/About.vue";
import Scoreboard from "../views/Scoreboard.vue";
import EnrolledTeams from "../views/EnrolledTeams.vue";
import Problems from "../views/Problems.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/my-account/:site?",
    name: "My Account",
    component: MyAccount
  },
  {
    path: "/enrolled",
    name: "Enrolled",
    component: EnrolledTeams
  },
  {
    path: "/score",
    name: "Scoreboard",
    component: Scoreboard
  },
  {
    path: "/problems",
    name: "Problems",
    component: Problems
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "*",
    component: NotFound
  }
];

const router = new VueRouter({
  // mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  document.title =
    to.name === undefined ? "Not found - Knattra" : `${to.name} - Knattra`;
  document.querySelector(".content")?.scrollTo({ top: 0, behavior: "smooth" });
  next();
});

export default router;
