




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppButton extends Vue {
  @Prop() private text!: string;
  @Prop() private click!: () => void;
  @Prop({ default: false }) private disabled?: boolean;
  @Prop({ default: false }) private hide?: boolean;
  @Prop({ default: false }) private mobile?: boolean;
  @Prop({ default: false }) private dark?: boolean;
  @Prop({ default: false }) private smallButton?: boolean;
  @Prop({ default: false }) private registerButton?: boolean;
  @Prop({ default: false }) private submitButton?: boolean;
}
