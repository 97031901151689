var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:{
    small: _vm.mobile,
    normal: !_vm.mobile,
    dark: _vm.dark,
    hide: _vm.hide,
    disabledStyle: _vm.disabled,
    smallButton: _vm.smallButton,
    registerButton: _vm.registerButton,
    submitButton: _vm.submitButton
  },attrs:{"disabled":_vm.disabled},on:{"click":_vm.click}},[_vm._v(" "+_vm._s(_vm.text)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }