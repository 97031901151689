









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LinkButton extends Vue {
  @Prop() private text!: string;
  @Prop() private click?: () => void;
  @Prop({ default: false }) private hide?: boolean;
}
