







import Vue from "vue";
import { Component } from "vue-property-decorator";
import LinkButton from "@/components/LinkButton.vue";

@Component({
  components: {
    LinkButton
  }
})
export default class AppFooter extends Vue {}
