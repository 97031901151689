




























import { Component, Vue, Prop } from "vue-property-decorator";
import AppButton from "@/components/AppButton.vue";

@Component({
  components: {
    AppButton
  }
})
export default class Modal extends Vue {
  @Prop() private header!: string;
  @Prop() private text!: string;
  @Prop({ required: false }) private cancelAction?: () => void;
  @Prop({ required: false }) private confirmAction?: () => void;
}
