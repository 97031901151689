


















import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Title from "@/components/Title.vue";

@Component({
  computed: { ...mapGetters(["teams"]) },

  components: {
    Title
  }
})
export default class EnrolledTeams extends Vue {}
