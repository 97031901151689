









































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import Title from "@/components/Title.vue";

@Component({
  computed: { ...mapState(["problems"]), ...mapGetters(["scoreboard"]) },
  components: { Title }
})
export default class Scoreboard extends Vue {}
