
































import { Component, Vue } from "vue-property-decorator";
import Problem from "@/components/Problem.vue";
import Title from "@/components/Title.vue";
import { mapState } from "vuex";

import { db, isCompetitionOpen } from "../firebase";
import logger from "@/utilities/logger";

@Component({
  computed: mapState(["isSmallDevice", "isAuth"]),
  components: { Problem, Title },
  methods: { isCompetitionOpen }
})
export default class Problems extends Vue {
  private problems: Record<string, any>[] = [];
  private competitionOpen = false;

  created() {
    this.fetchProblems();
  }

  async fetchProblems() {
    this.competitionOpen = await isCompetitionOpen();

    const problems = await db
      .collection("problems")
      .where("active", "==", true)
      .orderBy("order")
      .get();
    this.problems = problems.docs.map((problem: any) => ({
      id: problem.id,
      ...problem.data()
    }));

    if (!this.competitionOpen) {
      this.problems.splice(1);
    }
  }

  parseHTML(str: string) {
    try {
      str = str.replace(/\\\\json(.*)\\\\nosj/g, (_, a) =>
        JSON.stringify(JSON.parse(a), null, 2)
      );
    } catch (error) {
      logger.error(error);
    }
    return str;
  }
}
